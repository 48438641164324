



const Avatars = [
    "",
    require(`../assets/avatars/avatar1.png`),
    require(`../assets/avatars/avatar2.png`),
    require(`../assets/avatars/avatar3.png`),
    require(`../assets/avatars/avatar4.png`),
    require(`../assets/avatars/avatar5.png`),
    require(`../assets/avatars/avatar6.png`),
    require(`../assets/avatars/avatar7.png`),
    require(`../assets/avatars/avatar8.png`),
    require(`../assets/avatars/avatar9.png`),
    require(`../assets/avatars/avatar10.png`),
    require(`../assets/avatars/avatar11.png`),
    require(`../assets/avatars/avatar12.png`),
    require(`../assets/avatars/avatar13.png`),
    require(`../assets/avatars/avatar14.png`),
    require(`../assets/avatars/avatar15.png`),
    require(`../assets/avatars/avatar16.png`),
    require(`../assets/avatars/avatar17.png`),
    require(`../assets/avatars/avatar18.png`),
    require(`../assets/avatars/avatar19.png`),
    require(`../assets/avatars/avatar20.png`),
    require(`../assets/avatars/avatar21.png`),
    require(`../assets/avatars/avatar22.png`),
    require(`../assets/avatars/avatar23.png`),
    require(`../assets/avatars/avatar24.png`),
    require(`../assets/avatars/avatar25.png`),
    require(`../assets/avatars/avatar26.png`),
    require(`../assets/avatars/avatar27.png`),
    require(`../assets/avatars/avatar28.png`),
    require(`../assets/avatars/avatar29.png`),
    require(`../assets/avatars/avatar30.png`),
    require(`../assets/avatars/avatar31.png`),
    require(`../assets/avatars/avatar32.png`),
    require(`../assets/avatars/avatar33.png`),
    require(`../assets/avatars/avatar34.png`),
    require(`../assets/avatars/avatar35.png`),
    require(`../assets/avatars/avatar36.png`),
    require(`../assets/avatars/avatar37.png`),
    require(`../assets/avatars/avatar38.png`),
    require(`../assets/avatars/avatar39.png`),
    require(`../assets/avatars/avatar40.png`),
    require(`../assets/avatars/avatar41.png`),
    require(`../assets/avatars/avatar42.png`),
    require(`../assets/avatars/avatar43.png`),
    require(`../assets/avatars/avatar44.png`),
    require(`../assets/avatars/avatar45.png`),
    require(`../assets/avatars/avatar46.png`),
    require(`../assets/avatars/avatar47.png`),
    require(`../assets/avatars/avatar48.png`),
    require(`../assets/avatars/avatar49.png`),
    require(`../assets/avatars/avatar50.png`),
    require(`../assets/avatars/avatar51.png`),
    require(`../assets/avatars/avatar52.png`),
    require(`../assets/avatars/avatar53.png`),
    require(`../assets/avatars/avatar54.png`),
    require(`../assets/avatars/avatar55.png`),
    require(`../assets/avatars/avatar56.png`),
    require(`../assets/avatars/avatar57.png`),
    require(`../assets/avatars/avatar58.png`),
    require(`../assets/avatars/avatar59.png`),
    require(`../assets/avatars/avatar60.png`),
    require(`../assets/avatars/avatar61.png`),
    require(`../assets/avatars/avatar62.png`),
    require(`../assets/avatars/avatar63.png`),
    require(`../assets/avatars/avatar64.png`),
    require(`../assets/avatars/avatar65.png`),
    require(`../assets/avatars/avatar66.png`),
    require(`../assets/avatars/avatar67.png`),
    require(`../assets/avatars/avatar68.png`),
    require(`../assets/avatars/avatar69.png`),
    require(`../assets/avatars/avatar70.png`),
    require(`../assets/avatars/avatar71.png`),
    require(`../assets/avatars/avatar72.png`),
    require(`../assets/avatars/avatar73.png`),
    require(`../assets/avatars/avatar74.png`),
    require(`../assets/avatars/avatar75.png`),
    require(`../assets/avatars/avatar76.png`),
    require(`../assets/avatars/avatar77.png`),
    require(`../assets/avatars/avatar78.png`),
    require(`../assets/avatars/avatar79.png`),
    require(`../assets/avatars/avatar80.png`),
];

export default Avatars;