import { View } from "react-native-web"
import React, {useState, useEffect, useContext } from 'react';
import styles from '../styles/defaultStyle';
import Spacer from '../shared/Spacer';
import {Login, GetGames, GetImage} from '../shared/HiscoreAPI';

export const HighscoreScreen = ({}) => {


    return (

        <View>

        </View>
    )

};